<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>分销管理</a-breadcrumb-item>
    <a-breadcrumb-item>短剧分销数据</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 提示 -->
  <a-alert :message="`今日充值数据整点更新，最新更新时间 ${refreshTime}`" type="warning" show-icon style="margin-top: 20px;" />
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期范围： -->
    <span class="tool-title">日期范围：</span>
    <a-range-picker
      v-model:value="dateRangeModel"
      :format="dateFormat"
    />
    <!-- 分销商： -->
    <span class="tool-title">分销商：</span>
    <a-select v-model:value="resellerIDModel" style="width: 160px;" placeholder="请选择" allow-clear show-search :filter-option="filterOption" >
      <a-select-option v-for="item in resellers" :key="`${item.id}-${item.name}`" :value="item.id">{{ item.name }}</a-select-option>
    </a-select>
    <!-- 短剧 -->
    <span class="tool-title">短剧：</span>
    <a-select v-model:value="dramaIDModel" style="width: 160px;" placeholder="请选择" allow-clear show-search :filter-option="filterOption">
      <a-select-option v-for="item in dramas" :key="`${item.id}-${item.remark_name}`" :value="item.id">{{ item.remark_name }}</a-select-option>
    </a-select>
    <!-- 合并数据 -->
    <span class="tool-title">合并数据：</span>
    <a-select v-model:value="mergeTypeModel" style="width: 160px;" placeholder="请选择">
      <a-select-option :value="0">否</a-select-option>
      <a-select-option :value="1">是</a-select-option>
    </a-select>
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="touchSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="touchReset">重置</a-button>
    <!-- 弹簧 -->
    <div style="flex: 1;"></div>
    <!-- 操作 -->
    <a-button @click="touchDownload">导出数据</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="false"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <template v-if="
        column.key === 'total_income_amount' ||
        column.key === 'total_iaa_ad_income' ||
        column.key === 'total_iaa_pay_amount'
      ">
        {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="touchDayData(record)">每日数据</a>
        <a class="operation-item" @click="touchDramaData(record)">短剧数据</a>
      </template>
    </template>
  </a-table>
  <!-- 自定义分页器 -->
  <a-pagination
    v-if="dataSource.length"
    style="margin: 16px 0; text-align: right;"
    v-model:current="pagination.current"
    v-model:pageSize="pagination.pageSize"
    :total="pagination.total"
    :showSizeChanger="pagination.showSizeChanger"
    @change="handlePaginationChange"
  />
  <DayDataDrawer ref="RefDayDataDrawer"></DayDataDrawer>
  <DramaDataDrawer ref="RefDramaDataDrawer"></DramaDataDrawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import * as Ex from "@netjoy/excelex"
import Pub from '@/utils/public'
import dayjs from 'dayjs'
import DayDataDrawer from './components-reseller-data/DayDataDrawer'
import DramaDataDrawer from './components-reseller-data/DramaDataDrawer'
import { resellerDramaData, resellerUser, resellerDrama } from '@/api/copyright'

// 每日数据
let RefDayDataDrawer = ref(null)
// 短剧数据
let RefDramaDataDrawer = ref(null)
// 更新时间
let refreshTime = ref('')
// 加载
let isLoading = ref(false)
// 分销商
let resellerIDModel = ref(undefined)
let resellerID = ref(undefined)
let resellers = ref([])
// 日期范围
const dateFormat = 'YYYY-MM-DD'
let dateRangeModel = ref([dayjs().subtract(30, 'day'), dayjs()])
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 短剧
let dramaIDModel = ref(undefined)
let dramaID = ref(undefined)
let dramas = ref([])
// 合并数据
let mergeTypeModel = ref(0)
let mergeType = ref(0)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '日期',
    dataIndex: 'data_date',
    key: 'data_date'
  },
  {
    title: '短剧',
    dataIndex: 'drama_name',
    key: 'drama_name'
  },
  {
    title: 'IAP充值金额',
    dataIndex: 'total_income_amount',
    key: 'total_income_amount'
  },
  {
    title: 'IAA广告收入',
    dataIndex: 'total_iaa_ad_income',
    key: 'total_iaa_ad_income'
  },
  {
    title: 'IAA充值金额',
    dataIndex: 'total_iaa_pay_amount',
    key: 'total_iaa_pay_amount'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 初始化选择项列表
  getSelectList()
  // 获取推广数据列表
  getList()
})

// 模糊匹配
function filterOption (input, option) {
  console.log(option)
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 每日数据
function touchDayData (record) {
  RefDayDataDrawer.value.showDrawer(record)
}

// 短剧数据
function touchDramaData (record) {
  RefDramaDataDrawer.value.showDrawer(record)
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 分页处理
function handlePaginationChange (current, pageSize) {
  pagination.current = current
  pagination.pageSize = pageSize
  getList()
}

// 查询
function touchSearch () {
  // 调整参数
  resellerID.value = resellerIDModel.value
  dateRange.value = dateRangeModel.value
  dramaID.value = dramaIDModel.value
  mergeType.value = mergeTypeModel.value
  pagination.current = 1
  // 获取列表
  getList()
}

// 重置
function touchReset () {
  dateRangeModel.value = [dayjs().subtract(30, 'day'), dayjs()]
  dramaIDModel.value = undefined
  dramaID.value = undefined
  mergeTypeModel.value = 0
  mergeType.value = 0
  resellerIDModel.value = undefined
  resellerID.value = undefined
  // 初始化获取
  touchSearch()
}

// 获取推广数据列表
function getList () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    reseller_id: resellerID.value,
    drama_id: dramaID.value,
    is_merge: mergeType.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  resellerDramaData(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data.concat([data.sumarry])//[].concat(data.data).concat([data.sumarry])
      pagination.total = data.total
      refreshTime.value = data.refresh_time
    } else {
      message.error(res.message || msg)
    }
  })
}

// 初始化选择项列表
function getSelectList () {
  resellerUser().then(res => {
    const { code, data } = res
    if (code === 0) {
      resellers.value = data
    }
  })
  resellerDrama().then(res => {
    const { code, data } = res
    if (code === 0) {
      dramas.value = data
    }
  })
}

// 点击导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    reseller_id: resellerID.value,
    drama_id: dramaID.value,
    is_merge: mergeType.value,
    page: 1,
    page_size: 100000
  }
  resellerDramaData(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      // 将汇总数据插入第一条
      const list = [].concat(data.data).concat([data.sumarry])
      if (list.length) {
        const columns = [
          {
            name: '日期',
            field: 'data_date'
          },
          {
            name: '短剧',
            field: 'drama_name'
          },
          {
            name: 'IAP充值金额',
            field: 'total_income_amount'
          },
          {
            name: 'IAA广告收入',
            field: 'total_iaa_ad_income'
          },
          {
            name: 'IAA充值金额',
            field: 'total_iaa_pay_amount'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            // 金额处理
            if (
              field === 'total_income_amount' ||
              field === 'total_iaa_ad_income' ||
              field === 'total_iaa_pay_amount'
            ) {
              if (item.data != '-') {
                item.data = Pub.KEEP_NUMBER_DECIMAL(item.data / 100, 2, false, true)
              }
              return item
            }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        },  `分销管理-短剧分销数据`)
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch(() => {
    message.error('导出失败')
  })
}
</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
</style>